// External Dependencies
import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';

// Local Variables
const propTypes = {
  linkText: PropTypes.string,
  openInNewTab: PropTypes.bool,
};

// Component Definition
const TermsOfService = ({
  linkText = 'Terms of Service',
  openInNewTab = false,
}) => {
  if (openInNewTab) {
    return (
      <a
        href="/terms_of_service"
        target="_blank"
      >
        {linkText}
      </a>
    );
  }

  return (
    <Link to="/terms_of_service">
      {linkText}
    </Link>
  );
};

TermsOfService.propTypes = propTypes;

export default TermsOfService;
