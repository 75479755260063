// External Dependencies
import PropTypes from 'prop-types';
import React from 'react';

// Internal Dependencies
import BlogHeader from './BlogHeader';
import Footer from './Footer';
import ReleasesHeader from './ReleasesHeader';
import SupportSection from './SupportSection';

// Local Variables
const propTypes = {
  children: PropTypes.node.isRequired,
  hideFooter: PropTypes.bool,
  variant: PropTypes.oneOf(['blog', 'curve', 'default', 'releases']),
};

const defaultProps = {
  hideFooter: false,
  variant: 'default',
};

// Component Definition
class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 'is-loading',
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  getLayout = () => {
    const {
      children,
      hideFooter,
      variant,
    } = this.props;

    const blogLayout = (
      <div>
        <BlogHeader />
        <div id="blog-wrapper">
          {children}
        </div>
        <div id="blog-footer-wrapper">
          <Footer />
        </div>
      </div>
    );

    const releaseLayout = (
      <div>
        <ReleasesHeader />
        <div id="releases-wrapper">
          {children}
        </div>

        <SupportSection />

        <div id="blog-footer-wrapper">
          <Footer />
        </div>
      </div>
    );

    const stockLayout = (
      <div id={variant === 'curve' ? 'curve-wrapper' : 'wrapper'}>
        {children}

        <SupportSection />

        {!hideFooter && <Footer />}
      </div>
    );

    let layout;
    switch (variant) {
      case 'blog':
        layout = blogLayout;
        break;
      case 'releases':
        layout = releaseLayout;
        break;
      case 'releaseNotes':
        layout = releaseLayout;
        break;
      case 'default':
        layout = stockLayout;
        break;
      default:
        layout = stockLayout;
        break;
    }
    return layout;
  };

  render() {
    return (
      <div className={`body ${this.state.loading}`}>
        {this.getLayout()}
      </div>
    );
  }
}

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
