// External Dependencies
import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';

// Local Variables
const propTypes = {
  linkText: PropTypes.string,
  openInNewTab: PropTypes.bool,
};

// Component Definition
const PrivacyPolicy = ({
  linkText = 'Privacy Policy',
  openInNewTab = false,
}) => {
  if (openInNewTab) {
    return (
      <a
        href="/privacy_policy"
        target="_blank"
      >
        {linkText}
      </a>
    );
  }

  return (
    <Link to="/privacy_policy">
      {linkText}
    </Link>
  );
};

PrivacyPolicy.propTypes = propTypes;

export default PrivacyPolicy;
