// External Dependencies
import React from 'react';

// Component Definition
const SupportSection = () => {
  return (
    <article className="cta panel bg-presto-shade color-light">
      <div className="section-wrapper">
        <div className="cta-inner flow">
          <h2 className="color-light cta-heading headline">Find the help you need</h2>

          <p className="cta-summary measure-short">
            The Presto Assistant support archive has many detailed articles
            to help you find what you need. Get in touch if you need any help!
          </p>

          <div className="cta-action">
            {/* Our own support site is ok to link back to the marketing site. */}
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              className="transparent-button"
              data-variant="ghost"
              href="https://support.presto-assistant.com"
              target="_blank"
            >
              Visit the support archive
            </a>
          </div>
        </div>
      </div>
    </article>
  );
};

export default SupportSection;
