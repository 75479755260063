// External Dependencies
import React from 'react';
import { Link } from 'gatsby';

// Component Definition
const BlogHeader = () => {
  let isBlogHome;
  if (typeof window !== 'undefined') {
    isBlogHome = window.location.href.endsWith('blog');
  }

  return (
    <header
      className="alt"
      id="blog-header"
    >
      <nav id="nav-bar">
        <Link className="blog-nav-link" to="/">Presto Home</Link>
        {!isBlogHome && (
          <Link
            className="blog-nav-link"
            to="/blog"
          >
            Blog
          </Link>
        )}
      </nav>
      <h1>
        The Presto Blog
      </h1>
      <p>
        Stay up-to-date with the latest Presto news.
      </p>
    </header>
  );
};

export default BlogHeader;
