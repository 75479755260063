// External Dependencies
import React from 'react';
import { Link } from 'gatsby';

// Internal Dependencies
import {
  PrivacyPolicy,
  TermsOfService,
} from './shared/Links';
import PrestoLogoTextWhite from './shared/PrestoLogoTextWhite.svg';
import { supportSiteUrl } from '../utils/constants';

// Component Definition
const Footer = () => (
  <footer id="footer">
    <div id="footer-logo-container">
      <Link aria-label="Link to top of page" to="/">
        <PrestoLogoTextWhite width="240px" />
      </Link>
    </div>

    <section>
      <h2>About the Team</h2>
      <p>
        Presto is the work of a team of music educators-turned-developers
        who know first-hand what it takes to manage a fine arts program.
      </p>
    </section>

    <section>
      <h2>Resources</h2>

      <ul className="resources-list">
        <li>
          <a href="/contact_support">
            Contact Us
          </a>
        </li>
        <li>
          {/* It's our own support site. We don't mind if the referrer is www.presto */}
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href={supportSiteUrl} target="_blank">
            Support
          </a>
        </li>
        <li>
          <Link to="/faqs">
            FAQs
          </Link>
        </li>
        <li>
          <Link to="/features">
            List of Features
          </Link>
        </li>
        <li>
          <Link to="/meet_the_team">
            Meet the Team
          </Link>
        </li>
        <li>
          <Link to="/releases">
            Releases
          </Link>
        </li>
        <li>
          <a href="/tba_2022/index.html">
            TBA 2022 Product Showcase
          </a>
        </li>
      </ul>

      <ul className="icons">
        <li>
          <a
            aria-label="Link to Facebook"
            className="icon fa-facebook alt"
            href="https://www.facebook.com/Presto-Assistant-1058031084381957"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            aria-label="Link to YouTube"
            className="icon fa-youtube alt"
            href="https://www.youtube.com/channel/UCgdcIp467KUKmHORFy1_SUg"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="label">YouTube</span>
          </a>
        </li>
        <li>
          <a
            aria-label="Link to Twitter"
            className="icon fa-twitter alt"
            href="https://twitter.com/PrestoAssistant"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
      </ul>
    </section>

    <div className="legal">
      <ul>
        <li>
          &copy; {new Date().getFullYear()} Presto Assistant
        </li>
        <li>
          <TermsOfService />
        </li>
        <li>
          <PrivacyPolicy />
        </li>
        <li>
          {/* Do not use Gatsby Link here. This is a static html document. */}
          <a href="/accessibility_conformance_report.html">
            Accessibility
          </a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
