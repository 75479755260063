// External Dependencies
import React from 'react';

// Internal Dependencies
import Layout from '../components/layout';

// Component Definition
const NotFoundPage = () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
