// External Dependencies
import React from 'react';
import { Link } from 'gatsby';

// Component Definition
const ReleasesHeader = () => {
  let isReleasesHome;
  if (typeof window !== 'undefined') {
    isReleasesHome = window.location.href.endsWith('releases');
  }

  return (
    <header
      className="alt"
      id="releases-header"
    >
      <nav id="nav-bar">
        <Link className="release-nav-link" to="/">← Presto Home</Link>
        {!isReleasesHome && (
          <Link
            className="release-nav-link"
            to="/releases"
          >
            Releases
          </Link>
        )}
      </nav>

      <h1>
        Presto Releases
      </h1>
    </header>
  );
};

export default ReleasesHeader;
